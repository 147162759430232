import { render, staticRenderFns } from "./color.vue?vue&type=template&id=058e5059&scoped=true"
import script from "./color.vue?vue&type=script&lang=ts"
export * from "./color.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058e5059",
  null
  
)

export default component.exports